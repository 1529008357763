export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#84C600',
    contrastText: '#FFFFFF',
    light: '#F1FAFE',
  },
  secondary: {
    main: '#004485',
    contrastText: '#002855',
  },
  text: {
    primary: '#2E2A39',
    light: 'rgba(46, 42, 57, 0.7)',
    secondary: '#64748B',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#F5F5F5',
    primary: '#43C2BD',
    secondary: '#4E9ED6',
    paper: '#FFF',
    default: '#FFF',
    header: '#004485',
    footer: '#4E9ED6',
    level2: '#f7f8fb',
    level1: '#FFF',
    tableHead: '#4E9ED6',
    tableSubhead: '#F7F7F7',
    arrow: '#10B7F2',
    tableAlternate: '#F8F8F8',
    alternate: '#E3F1FD',
    success: '#DBEFDC',
    error: '#FFEAEA',
    disclaimer: "#101D29"
  },
  verticalBar: {
    used: '#84C600',
    unused: '#f2f9e5',
    unlimited: '#C0C0C0',
  }
};
