import { createTheme, responsiveFontSizes } from '@material-ui/core';
import { palette } from './palette';
import { homeStyle } from './home-styles';

const Theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette,
    layout: {
      contentWidth: 1600,
    },
    typography: {
      fontFamily: 'Instrument Sans',
      h1: {
        fontWeight: 500,
        letterSpacing: 0.75
      },
      h2: {
        fontSize: 55,
        fontWeight: 500,
        letterSpacing: 0.75
      },
      h3: {
        fontWeight: `${500} !important`,
        letterSpacing: 0.75
      },
      h4: {
        fontWeight: `${400} !important`,
        letterSpacing: 0.75
      },
      h5: {
        fontWeight: `${400} !important`,
        letterSpacing: 0.75
      },
      h6: {
        fontWeight: `${400} !important`,
        letterSpacing: 0.75
      }
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    customValues: {
      borderRadius: 10,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'auto',
          },
          body: {
            fontFamily: [
              'VisbyCF',
              '-apple-system',
              'BlinkMacSystemFont',
              'Segoe UI',
              'Roboto',
              'Oxygen',
              'Ubuntu',
              'Cantarell',
              'Fira Sans',
              'Droid Sans',
              'Helvetica Neue',
              'sans-serif',
              'Poppins',
              'Instrument Sans'
            ].join(', '),
          },
          a: {
            textDecoration: 'none',
            color: palette.primary.main,
          },
          '.bold': {
            fontWeight: 700,
          },
          '.semibold': {
            fontWeight: 600,
          },
          '.fullHeight': {
            height: '100%',
          },
          '.success': {
            color: `${palette.text.success} !important`,
          },
          '.recharts-legend-item': {
            marginTop: 15,
            marginBottom: 15,
          },
          ol: {
            paddingLeft: 32,
          },
          ...homeStyle,
          '.react-multiple-carousel__arrow--left': {
            left: 0
          },
          '.react-multiple-carousel__arrow--right': {
            right: 0
          },
          '.react-multiple-carousel__arrow--left::before': {
            content: '"\\2190"'
          },
          '.react-multiple-carousel__arrow--right::before': {
            content: '"\\2192"'
          },
          '.react-multiple-carousel__arrow': {
            background: palette.primary.main
          },
          '.react-multi-carousel-dot button': {
            border: 'none',
            height: 6,
            width: 35,
            borderRadius: 0,
            transition: 'all 0.3s',
            background: palette.text.disabled,

            '&:hover:active': {
              background: palette.primary.main,
            },
          },
          '.react-multi-carousel-dot--active button': {
            width: 70,
            backgroundColor: palette.secondary.main,
          },
          '.plan-selection-container .react-multi-carousel-dot button': {
            background: palette.primary.main,
            opacity: '0.2'
          },
          '.plan-selection-container .react-multi-carousel-dot--active button': {
            backgroundColor: palette.primary.main,
            opacity: '1'
          },
          '.hero-banner': {
            display: 'none',
          },
          '.planCard': {
            borderRadius: '12px !important',
          },
          '.wp-content': {
            fontSize: '0.93rem',
            lineHeight: 1.5,
            color: palette.text.primary,

            '& h6': {
              fontSize: 16,
              margin: '0 0 8px',
            },
            '& p': {
              margin: '20px 0',
            },
            '& li': {
              margin: '20px 0',

              '& li': {
                listStyleType: 'lower-roman'
              }
            },
            '& .heading': {
              fontSize: '2rem',
              margin: '40px 0 0',
            },
            '& .subHeading': {
              fontSize: '1.5rem',
              margin: '35px 0 0',
            },
            '& .sectionHeading': {
              fontSize: '1.25rem',
              margin: '35px 0 0',
            },
          },
        },
      },
      MuiAccordionSummary: {
        expandIcon: {
          '& .collapseIcon': {
            display: 'none',
          },
          '&$expanded': {
            '& .collapseIcon': {
              display: 'block',
            },
            '& .expandIcon': {
              display: 'none',
            },
          },
        },
      },
      MuiAlert: {
        outlinedSuccess: {
          background: palette.background.success,
        },
        outlinedError: {
          background: palette.background.error,
        },
      },
      MuiButton: {
        root: {
          fontWeight: 700,
          textTransform: 'none',
          letterSpacing: 0.75,
          padding: 13,
          boxShadow: 'none !important',
          borderRadius: 80,

          '&.pill': {
            borderRadius: 30,
          },

          '&.primary-contrast': {
            background: palette.primary.contrastText,
            color: palette.primary.main,
          },

          '&:hover': {
            boxShadow: 'none !important',
          }
        },
        outlined: {
          padding: 13,
          borderWidth: 2,
          borderColor: palette.primary.main,
          background: palette.background.default,
          '&:hover': {
            borderColor: palette.primary.main,
            background: palette.background.default,
          }
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: palette.background.header
          }
        },
        containedSecondary: {
          color: palette.text.highlight
        },
        outlinedPrimary: {
          '&:hover': {
            borderColor: palette.primary.main,
            color: palette.primary.main
          }
        },
        outlinedSecondary: {
          '&:hover': {
            borderColor: palette.secondary.main,
            backgroundColor: palette.text.highlight,
            color: palette.secondary.main
          }
        },
        startIcon: {
          marginLeft: 0,
        },
      },
      MuiDrawer: {
        paper: {
          width: 450,
          maxWidth: '100%',
        },
      },
      MuiIconButton: {
        colorPrimary: {
          color: palette.primary.main,
        },
        colorSecondary: {
          backgroundColor: palette.background.paper,

          '&:hover': {
            backgroundColor: palette.background.level2,
          },
        },
      },
      MuiButtonGroup: {
        root: {
          border: `1px solid ${palette.primary.main}`,
          padding: 4,
          borderRadius: 20,
        },
        grouped: {
          borderRadius: 20,
          fontSize: '0.7rem',
        },
        groupedHorizontal: {
          '&:not(:first-child)': {
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
          },
          '&:not(:last-child)': {
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
          },
        },
        groupedTextHorizontal: {
          '&:not(:last-child)': {
            borderRight: 0,
          },
        },
      },
      MuiInputBase: {
        input: {
          scrollMargin: '100px',
        },
      },
      MuiInputAdornment: {
        positionEnd: {
          height: '100%',
          position: 'relative',
          background: palette.background.paper,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 10,
        },
      },
      MuiPaper: {
        root: {
          '&.widget': {
            padding: 24,
          },
        },
        rounded: {
          borderRadius: 10,
        },
      },
      MuiCardContent: {
        root: {
          padding: 24,
        },
      },
      MuiSelect: {
        nativeInput: {
          scrollMargin: '150px',
        },
      },
      MuiSnackbar: {
        anchorOriginTopCenter: {
          top: '85px !important',
        },
      },
      MuiTab: {
        root: {
          '&.compact': {
            padding: '12px 16px'
          },
        },
      },
      MuiTabs: {
        root: {
          '&.subscription-tabs': {
            '& .MuiTab-wrapper': {
              textTransform: 'none',
            },
            '& .MuiTabs-scroller': {
              display: 'flex',
              justifyContent: 'center',
            },
            '& .MuiTabs-flexContainer': {
              background: palette.primary.contrastText,
              borderRadius: '2rem',

              '& .MuiTab-root': {
              },
              '& .Mui-selected': {
                background: palette.secondary.main,
                color: palette.secondary.contrastText,
                borderRadius: '2rem'
              }
            },
            '& .MuiTabs-indicator': {
              display: 'none'
            }
          }
        },
        vertical: {
          '& .MuiTab-root': {
            paddingLeft: 20,
          },
          '& .MuiTab-wrapper': {
            alignItems: 'flex-start',
          },
        },
      },
      MuiTabScrollButton: {
        root: {
          width: 25,
          '&$disabled': {
            width: 0,
            transition: 'all 0.2s',
          },
        },
      },
      PrivateTabIndicator: {
        vertical: {
          left: 0,
        },
        root: {
          height: 3,
        },
      },
      MuiTableRow: {
        root: {
          '&:nth-of-type(even)': {
            background: palette.background.tableAlternate,
          },
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: 0,
        },
        head: {
          background: palette.background.tableHead,
          padding: '12px 16px',
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: palette.background.tableHead,
        },
        data: {
          fontSize: '0.875rem',
          fontWeight: 500,
        },
      },
      MuiTableSortLabel: {
        root: {
          marginTop: 2,
        },
      },
      MuiTypography: {
        body1: {
          '@media(max-width: 600px)': {
            fontSize: '0.9rem',
          },
        },
      },
      MuiPickersDay: {
        current: {
          color: palette.text.primary
        }
      },
      MuiLinearProgress: {
        barColorSecondary: {
          backgroundColor: palette.verticalBar.unlimited
        }
      },
    },
  }),
);

export default Theme;
