import { palette } from './palette';
import background from 'assets/images/testimonial-bg.webp'
import headerBg from 'assets/images/header-bg.webp'

export const homeStyle = {
  '.drawer-wrapper': {
    '& .drawer-title-container, .drawer-close-button': {
      color: palette.text.highlight,
    },
  },
  '.popup-action-button': {
    color: palette.primary.contrastText
  },

  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {
      '& .hero-shaped__left-side': {
        flex: '0 0 50%',
        maxWidth: '50%',
  
        '@media(min-width: 900px) and (max-width: 1200px)': {
          flex: '0 0 50%',
          maxWidth: '50%',
        },
  
        '& .hero-shaped__image': {
          width: '50vw',
          
          '@media(min-width: 900px) and (max-width: 1200px)': {
            width: '50vw',
          }
        },
      },
    }
  },

  '.dental-container': {
    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0
    },

    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      backgroundColor: palette.background.header,

      '& .home-hero-pretitle': {
        paddingBottom: 32
      },

      '& .hero-background': {
        objectFit: 'cover',
        '@media screen and (max-width: 900px)': {
          height: '340px',
        },
      },
      '& .hero-section': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '600px',
        '& .section-header': {
          '@media screen and (min-width: 768px)': {
            marginTop: '60px',
          },
        },
      },
      '& .home-hero-title': {
        maxWidth: 500,
        fontWeight: 'bold',
        color: palette.text.highlight
      },
      '& .home-hero-subtitle': {
        maxWidth: 490,
        color: palette.text.highlight
      },
      '& .login-button': {
        background: palette.text.highlight,
        color: palette.secondary.main,
        borderColor: palette.secondary.main
      }
    },

    /* Benefits - Styles */
    '& #definition-section': {
      '& .benefits-wrapper': {
        padding: '0 10px',
        '& .section-header': {
          paddingBottom: 30
        },
        '& .definition-service-description': {
          paddingBottom: 40
        },

        '& .image': {
          borderRadius: 32,
        },

        '& .definition-service-tile': {
          padding: '0px 14px'
        },
        '@media(min-width: 900px)': {
          '& .section-header': {
            paddingBottom: 50
          },
        }
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      '& .subscription-bg': {
        height: '100%',
        background: 'linear-gradient(146deg, #4A99D2 0.77%, #034888 92.44%)',
      },
      '& #pricing-benefit-section': {
        '& .section-header__title, .section-header__subtitle, .subscription-tabs-label, .summary-text, .summary-subtext': {
          color: palette.text.highlight
        },
        '& .planCard': {
          '& .price-value': {
            fontSize: 40,
            fontWeight: 'bold',
            color: palette.background.header
          },
          '&:hover': {
            background: palette.primary.light,
            color: palette.text.primary,
            '& .highlight-divider': {
              background: palette.divider,
            },
          }
        },
        '@media(min-width: 900px)': {
          marginTop: 10,
        },

        '& .subscription-section': {
          '@media(min-width: 900px)': {
            marginTop: 10,
          },

          '& .section-header': {
            maxWidth: 720,

            '@media(min-width: 900px)': {
              margin: '0px auto 32px',
            },
          },
        },
        '& .savings-summary-link': {
          color: palette.text.highlight,
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
    },

    /* How it works - Styles */
    '& .howitworks-section': {
      background: palette.text.highlight,
      '& .howitworks-title': {
        marginBottom: 0,
      },
      '& .how-cover-image': {
        height: 450,
      },
      '& .step-counter': {
        backgroundColor: palette.background.secondary,
        fontSize: 16,
      },
      '& .step-content': {
        maxWidth: 400
      },
      '@media(max-width: 900px)': {
        '& .how-cover-image': {
          height: '100%',
        },
      }
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .service-plan-table': {
        '& .savings-topheader': {
          color: `${palette.text.highlight} !important`
        },
        '& .savings-header': {
          color: palette.text.primary
        }
      },
      '& .footer-bar': {
        background: palette.background.header
      },
      '& .savings-contact-card': {
        background: palette.background.footer
      }
    },

    /* Static Page Hero Banners */
    '& .dashboard-hero-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section, & .savings-hero-section, .find-provider-section, & .plan-selection-section, & .checkout-hero-section': {
      background: palette.background.header,
      backgroundImage: `url(${headerBg})`,
      backgroundSize: 'cover',
      '& .hero-header-title, .hero-header-subtitle': {
        color: palette.text.highlight
      }
    },
    '& .book-appointment-hero-section': {
      height: '100%',
      backgroundColor: palette.background.main,
      
      '& .MuiTypography-root': {
        color: palette.text.primary,
      }
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      '& .hero-static-bg': {
        backgroundColor: palette.background.secondary,
      },
      '& .hero-static-title, .hero-static-subtitle': {
        color: palette.text.highlight
      }
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      background: palette.background.main,
      '& .section-header__title-wrapper > h3': {
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: '0px',
          left: '35%',
          height: '2px',
          width: '29%',
        },
      },
      '@media(min-width: 900px)': {
        padding: 22,
      },
      '& .MuiAvatar-root': {
        display: 'none',
      },
      '& .MuiTypography-colorTextPrimary': {
        textAlign: 'center',
      },
      '& .avatar-section': {
        paddingTop: '20px',
        order: 2,
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .section-header': {
        order: 1,
      },
      '& .MuiPaper-root': {
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .MuiRating-root': {
        marginTop: 'initial',
        order: 3,
      },
      '& .MuiPaper-rounded': {
        background: 'transparent',
      },
      '& .avatar-container': {
        padding: 0,
        marginBottom: '5px',
      },
      '& .testimonialSection': {
        padding: '40px 32px',
        '@media(min-width: 900px)': {
          padding: '40px 16px',
        },
        '& .react-multiple-carousel__arrow': {
          background: palette.background.arrow,
        },
        '& .react-multi-carousel-item': {
          '& .MuiPaper-root': {
            marginInline: 100
          },
          '& .custom-avatar': {
            display: 'none'
          },
          '@media(max-width: 900px)' : {
            '& .MuiPaper-root': {
              marginInline: 0
            },
          }
        }
      },
    },

    /* FAQ */
    '& .faq-hero-section': {
      backgroundImage: `url(${headerBg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      '& .hero-header-title, .hero-header-subtitle': {
        margin: '0',
        textAlign: 'left',
        color: palette.text.highlight
      },
      '& .header-container': {
        justifyContent: 'center',
        margin: '0',
      },
      '& .section-header__title-wrapper, .section-header__subtitle-wrapper': {
        display: 'flex',
        justifyContent: 'center'
      },
      '& .hero-link-button': {
        '&:hover': {
          backgroundColor: palette.primary.main
        }
      }
    },
    '& .faqInnerContainer': {
      '& .accordion__collapsable-text': {
        color: 'rgba(71, 86, 112, 0.8)'
      },
      '& .faq-link': {
        fontWeight: 'normal'
      }
    },
    '& .faq-contact-wrapper': {
      background: palette.background.secondary,
      '& .faq-contact-section': {
        '& .contact-img-wrapper': {
          width: '64px',
          maxWidth: '64px',
        },
        '& .contact-card-title, .contact-card-subtitle, .provider-contact-link, .provider-contact-details': {
          color: palette.text.highlight
        },
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .provider-details': {
        border: `1px solid ${palette.text.border}`,
      },
      '& .planCard': {
        '&:hover': {
          background: palette.text.highlight,
          color: palette.text.primary,
          '& .highlight-divider': {
            background: palette.divider,
          },
        }
      },
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .plans-wrapper': {
        '& .highlight-button': {
          backgroundColor: palette.text.highlight,
        },
        '& .highlight-divider': {
          backgroundColor: palette.text.disabled,
        },
      },
      '& .primary-static-button': {
        background: palette.primary.main,
        color: palette.text.highlight
      }
    },
 
    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      }
    },

    /* Footer */
    '& .footer-wrapper, & .footer-wrapper a': {
      color: palette.text.highlight,
    },

    /* Dashboard Page - Appointment Header */
    '& .MuiTableRow-root .MuiTableCell-head': {
      color: palette.text.highlight,
    },
  },
};