import YesIcon from 'assets/images/yes-icon.svg';

import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';
import { ReactComponent as PreLogo } from 'assets/images/preLogo.svg';


import Logo from 'assets/images/logo.svg';
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_AWS_REGION: region,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
} = process.env;

const locations = [
  { key: 'LOC1', name: '1st Family Dental of Fox Valley'}
];

const locationApptLinks = locations.map((loc) => {
  const {
    [`REACT_APP_${loc.key}_APPT_LINK`]: locApptLink,
  } = process.env;
  return {
    name: loc.name,
    link: locApptLink,
    target: '_blank',
  };
});

const config = {
  groupId,
  region,
  userPoolId,
  userPoolWebClientId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: '1st Family Dental',
  tokenName: '1stfamilydentalSubscriberToken',
  globalClass: 'dental-container',
  clientHeader,
  enableHeroButton: true,
  pricingType: 'standard-pricing',
  headerProps: {
    enable: false,
  },
  homeTemplateProps: {
    heroSection: {
      variant: 2,
      preTitle: <PreLogo/>,
      preTitleClasses: 'home-hero-pretitle',
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      primaryButtonProps: {
        to: '/find-a-provider',
      },
      secondaryButtonProps: {
        to: '/login',
        color: 'primary',
      },
      background: '/images/home-images/home-banner-img.webp',
    },
    benefitsSection: {
      variant: 2,
      columns: 3,
      heroImage: '/images/home-images/benefits.webp',
    },
    subscriptionSection: {
      tabVariant: 2,
      carouselResponsiveItems: {
        desktop: 3,
      },
      planCardProps: {
        buttonProps: {
          highlightedColor: 'primary',
          highlightedVariant: 'contained',
        },
      },
    },
    howItWorksSection: {
      variant: 2,
      alignTitle: 'left',
      heroImage: '/images/home-images/how-it-works.webp',
    },
    testimonialSection: {
      variant: 2,
      messageType: 'h6',
      gridSpacing: {
        xSmall: 12,
        medium: 9,
      },
      carouselResponsiveItem: {
        superLarge: 1,
        large: 1,
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
      primaryButtonProps: {
        color: 'primary',
      },
    },
  },
  checkoutSection: {},
  findProviderSection: {
    locationWrapper: 7,
    mapWrapper: 5,
  },
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: 'contained',
        highlightedVariant: 'outlined',
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: true,
    bookAppointmentProps: {
      target: '_blank',
      locationList: locationApptLinks,
    },
  },
  savingsSummary: {
    enableFooterbar: true,
    showCategoryHeaders: true,
    contactSection: 'contactDetails',
    moreSavingsButtonProps: {
      variant: 'contained',
      color: 'primary'
    },
  },
  faqSection: {
    heroProps: {
      secondaryButtonProps: {
        variant: 'outlined',
        color: 'secondary',
      },
    },
  },
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  bookAppointment: {
    enableBookAppointment: true,
  },
  footer: {
    enable: false
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme,
};

export default config;
